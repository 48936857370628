import React, { useState, useCallback, useEffect } from "react";
import {Col, Row, Form, Button, Media, Card, Container} from "react-bootstrap";
import "./index.css";
import DommusRequiredSpan from "../../components/DommusRequiredSpan";
import guService from "../../services/GuService";
import eventoService from "../../services/EventoService";
import CessaoDireitosService from "../../services/CessaoDireitosService";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import DommusToggle from "../../components/DommusToggle";
import urlHelper from "../../helpers/UrlHelper";
import * as DocFileIcon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Eventos({ cor, dados, abrirModalImagem }) {
  const docName = dados.arquivos && dados.arquivos[0]?.nome ? dados.arquivos[0].nome : ''
  const docIcon = dados.arquivos && dados.arquivos[0]?.icone ? dados.arquivos[0].icone : "faFileAlt"

  function inscrever() {
    Swal.fire({
      titleText: `Inscrever-se em ${dados.nome}`,
      text: "Tem certeza que deseja realizar a inscrição?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (!result.value) {
      } else {
        handleSubscription(dados.id_evento);
      }
    });
  }


  function handleSubscription(idEvento) {
    eventoService.store(idEvento).then(
      (res) => {
        Swal.fire({
          titleText: "Inscrição realizada",
          text: `Sua inscrição foi realizada com sucesso!`,
          icon: "success",
        });
      },
      (error) => {
        Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao realizar a sua inscrição: " + error,
          icon: "error",
        });
      }
    );
  }

  return (
    <div>
      <div className="tituloNovaIndicacao" style={{ backgroundColor: cor }}>
        <h5>{dados?.nome}</h5>
      </div>
      <Form className="novaIndicacaoForm">
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Nome: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control type="textarea" value={dados?.nome} readOnly />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Data de início:</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={moment(dados?.data_inicio).format("DD/MM/YYYY HH:mm")}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formNome">
            <Form.Label>Data de fim:</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={moment(dados?.data_fim).format("DD/MM/YYYY HH:mm")}
            />
          </Form.Group>
        </Row>

       <Row>
          <Form.Group as={Col} controlId="formNome">
            <Form.Label>
              Descrição: <DommusRequiredSpan />
            </Form.Label>
            <Form.Control
              type="textarea"
              as="textarea"
              rows={10}
              value={dados?.descricao}
              readOnly
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formNome">
            <DommusToggle title={"Arquivos"} toggle={true}>
             <ul className="list-unstyled">
                {dados?.arquivos?.map((url, i) => (
                    <Media key={i} as="li" style={{ alignItems: "center" }}>
                      <FontAwesomeIcon icon={DocFileIcon[docIcon]} color="var(--dommus-color)" width="1.5rem" height="1.5rem" className="mr-2"/>
                      <Media.Body>
                        <a
                            onClick={(e) => {
                              e.preventDefault();
                              if (url.link.match(/https/gi)) {
                                urlHelper.download(url.link);
                              } else {
                                var newstr = url.link.replace(/http/gi, "https");
                                urlHelper.download(newstr);
                              }
                            }}
                        >
                          <Form.Label
                              style={{ cursor: "pointer", marginBottom: 0 }}
                          >
                            {docName ? docName : `Anexo ${i+1}`}
                          </Form.Label>
                        </a>
                      </Media.Body>
                    </Media>
                ))}
              </ul>
            </DommusToggle>
          </Form.Group>
        </Row>
        <Row>

        </Row>
        {dados?.habilitar_inscricao ? (
          <Row>
            <Col
              style={{
                marginBottom: 10,
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {dados?.inscricao == null ? (
                <Button
                  onClick={() => inscrever()}
                  className="botaoCadastroProcesso"
                >
                  Inscrever-se
                </Button>
              ) : (
                <div>
                  <span style={{ marginRight: 15 }}>
                    Você já se inscreveu, data:{" "}
                    {moment(dados?.inscricao.criado_em).format(
                      "DD/MM/yyyy HH:mm:ss"
                    )}
                  </span>
                  <span className="MuiBadge-root chamadoStatus2">
                    <span
                      className="MuiBadge-badge MuiBadge-anchorOriginTopRightRectangle MuiBadge-colorError badgeBase"
                      style={{
                        backgroundColor:
                          dados?.inscricao.situacao === "P"
                            ? "orange"
                            : dados?.inscricao.situacao === "R"
                            ? "red"
                            : "green",
                      }}
                    >
                      {dados?.inscricao.situacao === "P"
                        ? "PENDENTE"
                        : dados?.inscricao.situacao === "A"
                        ? "APROVADO"
                        : dados?.inscricao.situacao === "R"
                        ? "RECUSADO"
                        : ""}
                    </span>
                  </span>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
}
