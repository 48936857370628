import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Icon, InlineIcon } from "@iconify/react";
import arrowLeftSquare from "@iconify-icons/bi/arrow-left-square";
import DommusToggle from "../../../components/DommusToggle";
import "./index.css";
import twotoneAttachMoney from "@iconify-icons/ic/twotone-attach-money";
import Pagamentos from "./pagamentos";
import guAPI from "../../../services/GuService";
import ProcessoService from "../../../services/ProcessoService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import EmailService from "../../../services/EmailService";
import { errorToast } from "../../../components/DommusToast";
import guService from "../../../services/GuService";

export default function MeusPagamentos() {
  const [email, setEmail] = useState("");
  const [radioValue, setRadioValue] = useState("TDS");
  const [tiposParcela, setTiposParcela] = useState([]);

  useEffect(() => {
    ProcessoService.buscarProcessosCpf(guAPI.getLocalState("CPF"))
    .then((res) => {

      Object.values(res.data).map((item) => {
        guService.setLocalState("usuarioTipo", item.tipo_usuario);
        setEmail(item.email);
      });
    });
    if (guAPI.getLocalState("controle_financeiro")) {
      const tipos = JSON.parse(guAPI.getLocalState("controle_financeiro"));
      setTiposParcela(tipos);
    }
  }, []);

  function solicitadarExtrato() {
    Swal.fire({
      titleText: `Solicitação de Extrato financeiro`,
      text: `Tem certeza que deseja realizar a solicitação do envio de todos os extratos financeiros para ${email}?`,
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    })
      .then((result) => {
        if (result.value) {
          trackPromise(
            EmailService.enviarExtratoFinanceiro(guAPI.getLocalState("Processo"), email)
          )
            .then((response) => {
              Swal.fire({
                titleText: "Sucesso.",
                text: `Enviamos todos os extratos financeiros para ${email}`,
                icon: "success",
              });
            })
            .catch((error) => {
              errorToast.fire("Erro ao enviar email!");
            });
        }
      })
      .catch((error) => {
        console.log(error);
        errorToast.fire("Erro ao enviar o email!");
      });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Card className="cardMeusPagamentos">
            <Card.Body className="cadBoddy">
              <Link to="./">
                <Icon
                  className="iconFinaceiro"
                  style={{ backgroundColor: guAPI.getLocalState("cor-menu-3") }}
                  icon={twotoneAttachMoney}
                />
              </Link>
              <Card.Header
                className="cardHeader"
                style={{ backgroundColor: guAPI.getLocalState("cor-menu-3") }}
              >
                <div className="vazio"></div>
                <div className="tituloCadastro">Financeiro</div>
                <Link to="./">
                  <Icon className="iconVoltar" icon={arrowLeftSquare} />{" "}
                </Link>
              </Card.Header>
              <div className="direita_doc">
                <DommusToggle
                  title="Meus Pagamentos"
                  toggle={true}
                  button={
                    <button
                      onClick={() => {
                        solicitadarExtrato();
                      }}
                      className="btnExtrato"
                    >
                      Extrato financeiro
                    </button>
                  }
                  buttonMeio={
                    <ButtonGroup>
                      {tiposParcela.map((item, index) => {
                        return (
                          <ToggleButton
                            key={index}
                            id={`radio-${index}`}
                            type="radio"
                            variant="outline-warning"
                            name="radio"
                            value={item.valor}
                            checked={radioValue === item.valor}
                            onChange={(e) => {
                              setRadioValue(e.target.value);
                            }}
                            // className="btnExtrato"
                          >
                            {item.label}
                          </ToggleButton>
                        );
                      })}
                    </ButtonGroup>
                  }
                >
                  <Pagamentos radioValue={radioValue} />
                </DommusToggle>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
}
