import crm from './crm';

const widgetService = {
	buscarWidgetsVisiveis:()=>{
		return crm.get('/widget/monta-widgets');
	},
	buscarArquivosPorWidgetReferencia:(idWidget, refencia, idReferencia, idProcesso=null)=>{
		return crm.get(`/widget/${idWidget}/arquivos/referencia/${refencia}/${idReferencia}${idProcesso ? `/processo/${idProcesso}` : ''}`);
	},	
};

export default widgetService;