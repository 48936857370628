import { trackPromise } from "react-promise-tracker";
import crm from "./crm";

const eventoService = {
  index: (id) => {
    return crm.get("/evento/" + id);
  },
  store: (idEvento) => {
    return crm.post("/evento/" + idEvento + "/inscricao");
  },
  getEventosPorEmpreendimento: (idEmpreendimento) => {
    return crm.get(`/evento/${idEmpreendimento}`);
  },  
  searchLogEventosVisualizadosByIdsEventosAndCpf: (data) => {
    return trackPromise(crm.get('/evento/log-visualizado', {params: data}))
  },

  storeLogVisualizado: (id_evento, cpf) => {
    return trackPromise(crm.post('evento/log-visualizado', {id_evento, cpf}))
  }
};

export default eventoService;
